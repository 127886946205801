import React from 'react'
import { graphql } from 'gatsby'
import { PostList } from '../components/posts'
import { Pagination } from '../components/content'
import {
  MetaTagsMustHave, createPaginatedPageTitle, createPaginatedMetaProps,
} from '../components/seo'
import { PageLayoutDefault } from '../components/layout'

const Tag = ({ data, pageContext }) => {
  const { tag, posts } = data.wp

  return (
    <>
      <MetaTagsMustHave
        {...createPaginatedMetaProps(pageContext.humanPageNumber, tag.seo)}
      />
      <PageLayoutDefault
        heading={createPaginatedPageTitle(pageContext.humanPageNumber, tag.name)}
      >
        <PostList posts={posts.nodes} />
        <Pagination {...pageContext} />
      </PageLayoutDefault>
    </>
  )
}

export default Tag

export const pageQuery = graphql`
  query TagPage($first: Int!, $after: String, $tagId: ID!, $uri: ID!) {
    wp {
      tag(
        id: $uri
        idType: URI
      ) {
        name
        seo {
          title
          description: metaDesc
          datePublished: opengraphModifiedTime
          dateModified: opengraphPublishedTime
          opengraphImage {
            src: sourceUrl(size: LARGE)
          }
        }
      }
      posts(
        first: $first
        after: $after
        where: {
          status: PUBLISH
          language: DEFAULT
          tagIn: [$tagId]
          orderby: { field: DATE, order: DESC }
        }
      ) {
        nodes {
          ...PostListFields
          ...PostListImageFields
        }
      }
    }
  }
`
